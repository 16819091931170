import styled from '@emotion/styled'
import { Dialog, DialogContent } from '@mui/material'
import * as api from "api"
import CloseButton from "common/CloseButton"
import { memo } from "react"

type GalleryDialogProps = {
  onClose: () => void
  image: api.ImageModel
}

const Styled = {
  Dialog: styled(Dialog)`
    margin: 0 auto;
    max-width: 1200px;
  `,
  DialogContent: styled(DialogContent)`
    padding: 10px;
  `,
  img: styled.img`
    width: auto;
    max-width: 1200px;
  `,
}

const VehicleLevelsDialog = ({ onClose, image }: GalleryDialogProps) => {
  return (
    <Styled.Dialog open={image != null} onClose={onClose}>
      <CloseButton onClick={onClose} />
      <Styled.DialogContent>
        <Styled.img src={image?.imageUrl} alt={image?.alt} />
      </Styled.DialogContent>
    </Styled.Dialog>
  )
}

export default memo(VehicleLevelsDialog)
