import * as api from "api"
import { NextSeo } from "next-seo"
import nextLinks from "next.links"
import { memo } from "react"

export const seoKeys = { ...nextLinks, NotFound: "404", Homepage: "עמוד הבית" }
export type SeoKey = keyof typeof seoKeys

for (const key in seoKeys) {
  seoKeys[key] = encodeURIComponent(
    seoKeys[key]
      .replace(/\/\[.+/, "")
      .replace("/", "")
      .replace(/-/g, " ")
  )
}

type SeoMetaDataProps = { data: api.SeoMetaData | null }

const SeoMetaData = ({ data }: SeoMetaDataProps) => (
  <>
    {data && (
      <NextSeo
        title={data.title}
        description={data.description}
        additionalMetaTags={[{ name: "keywords", content: data.keywords }]}
        openGraph={{
          title: data.title,
          description: data.description,
          images: [{ url: data.image?.imageUrl || "" }],
        }}
      />
    )}
  </>
)

export default memo(SeoMetaData)
