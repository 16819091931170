import { SvgIconProps } from '@mui/material'
import Shared from "theme/shared"

const RearDoorsOpenAngleIcon = (props: SvgIconProps) => (
  <Shared.OptionsSVGIcon {...props} viewBox="0 0 50.146 50.146">
    <g>
      <path
        d="M46.3 254.454v49.184a.452.452 0 0 0 .113.334.446.446 0 0 0 .31.15.341.341 0 0 0 .055 0h49.21a.451.451 0 0 0 .456-.477v-1.835a.47.47 0 0 0-.482-.425h-12l-.008-.117a37.762 37.762 0 0 0-34.743-35.348l-.114-.009v-11.449a.451.451 0 0 0-.424-.484.3.3 0 0 0-.052 0h-1.837a.466.466 0 0 0-.334.113.446.446 0 0 0-.15.31.315.315 0 0 0 0 .053zm2.736 14.253l.135.012a35.063 35.063 0 0 1 31.923 32.533l.01.134H49.768a.782.782 0 0 1-.727-.676l-.005-.005v-32z"
        transform="translate(-46.298 -253.977)"
      />
      <path
        d="M92.105 294.979a.456.456 0 0 0 .816-.042l3.234-6.224a.5.5 0 0 0-.215-.676.492.492 0 0 0-.224-.056l-1.393.061h-.013a.727.727 0 0 1-.726-.662 36.141 36.141 0 0 0-30.548-30.544.773.773 0 0 1-.654-.726l.061-1.411a.482.482 0 0 0-.46-.5.435.435 0 0 0-.265.067L55.5 257.5a.531.531 0 0 0-.191.724.519.519 0 0 0 .142.16l5.907 3.777a.465.465 0 0 0 .345.078.458.458 0 0 0 .377-.458l.061-1.288a.8.8 0 0 1 .8-.73h.065a33.487 33.487 0 0 1 27.654 27.645.8.8 0 0 1-.721.876l-1.224.062a.464.464 0 0 0-.388.72z"
        transform="translate(-46.298 -253.977)"
      />
    </g>
  </Shared.OptionsSVGIcon>
)

export default RearDoorsOpenAngleIcon
