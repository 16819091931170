import styled from '@emotion/styled'
import { SvgIconProps } from '@mui/material'
import Shared from "theme/shared"

const StyledPath = styled.path`
  fill-rule: evenodd;
`
const EnginePowerIcon = (props: SvgIconProps) => (
  <Shared.OptionsSVGIcon {...props} viewBox="0 0 76.258 50.058">
    <g transform="translate(-64.839 -317.708)">
      <g>
        <StyledPath d="M96.357 324.132V334.4h7.7v-10.27h-2.565v7.7h-2.568v-7.7z" />
        <StyledPath d="M64.84 335.675v25.68h2.567v-25.68z" />
        <StyledPath d="M64.839 347.241v2.568h7.48v-2.568z" />
        <StyledPath d="M91.613 327.974a2.667 2.667 0 0 0-2.267 1.249l-3.2 5.17H73.677a2.627 2.627 0 0 0-2.642 2.57v23.1a2.627 2.627 0 0 0 2.642 2.572h11.368a4.049 4.049 0 0 1 2.479.847l3.713 2.884a6.725 6.725 0 0 0 4.126 1.4h25.9a2.613 2.613 0 0 0 2.644-2.578v-6.409h4.627l3.568 4.623a1.337 1.337 0 0 0 1.058.511h3.966a3.93 3.93 0 0 0 3.966-3.85V342.1a3.93 3.93 0 0 0-3.966-3.851h-3.965a1.336 1.336 0 0 0-1.058.516l-3.568 4.619h-4.628v-6.416a2.62 2.62 0 0 0-2.659-2.568h-7.168l-3.2-5.17a2.659 2.659 0 0 0-2.267-1.249H105.4v2.567h3.217l3.584 5.8a1.332 1.332 0 0 0 1.131.622h7.932v7.7a1.3 1.3 0 0 0 1.316 1.284h6.62a1.335 1.335 0 0 0 1.059-.512l3.568-4.623h3.3a1.285 1.285 0 0 1 1.322 1.245v18.006a1.283 1.283 0 0 1-1.283 1.284h-3.339l-3.568-4.619a1.336 1.336 0 0 0-1.059-.516h-6.61a1.3 1.3 0 0 0-1.321 1.278v7.703H95.362a4.044 4.044 0 0 1-2.479-.842l-3.713-2.879a6.719 6.719 0 0 0-4.126-1.409H73.677v-23.1H86.9a1.331 1.331 0 0 0 1.136-.622l3.578-5.8h3.217v-2.567z" />
        <StyledPath d="M89.919 317.708v7.7h20.54v-7.7zm2.567 2.568h15.4v2.567h-15.4z" />
        <StyledPath d="M121.37 344.665V357.5h2.568v-12.835z" />
        <StyledPath d="M77.811 335.675v25.68h2.567v-25.68z" />
        <StyledPath d="M128.26 344.665V357.5h2.567v-12.835z" />
        <StyledPath d="M114.77 335.675v5.135h2.567v-5.135z" />
        <StyledPath d="M103.207 360.075v2.568h19.256v-2.568z" />
      </g>
      <path d="M103.6 346.692h-4.5l3.119-5.441a1.348 1.348 0 0 0-2.339-1.341l-4.28 7.459a1.349 1.349 0 0 0 1.17 2.018h4.4L98 354.465a1.348 1.348 0 0 0 2.287 1.426l4.457-7.137a1.35 1.35 0 0 0-1.141-2.063z" />
    </g>
  </Shared.OptionsSVGIcon>
)

export default EnginePowerIcon
