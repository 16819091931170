import styled from "@emotion/styled"
import { Typography, css } from "@mui/material"
import * as api from "api"
import { formatNumber } from "common/utils/formatters"
import { memo } from "react"
import VehicleLevels from "./VehicleLevels"

export const LicensingFeeSmall = (p) => css`
  .notices-wrapper {
    margin-top: 0;
    margin-bottom: 0;
  }
  .notice {
    font-size: 10px;
    margin-top: 10px;
    ${p.theme.breakpoints.up("lg")} {
      margin-top: 0;
    }
  }
`

const Styled = {
  Wrapper: styled.div`
    margin: 5px 0;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    --gap: 2px;
    margin-inline-end: -var(--gap);
    margin-inline-start: -var(--gap);
    & > * {
      margin-inline-end: var(--gap);
      margin-inline-start: var(--gap);
    }
    ${(p) => p.theme.breakpoints.up("md")} {
      --gap: 0;
      align-items: flex-start;
    }
  `,
  Notice: styled(Typography)`
    text-align: left; /* flipped for mui */
    font-size: 12px;
    font-weight: 400;
    color: ${(p) => p.theme.colors.marine};
    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 16px;
    }

    white-space: break-spaces;
  `,
}

type VehicleNoticesProps =
  | { type: "HomePageVehicle"; data: api.HomePageVehicle }
  | { type: "ModelsVehicle"; data: api.ModelsVehicle }
  | { type: "Vehicle"; data: api.Vehicle }
  | {
      type: "VehicleChild"
      data: api.VehicleChildren | api.VehicleFinishingLevel
    }

const VehicleNotices = (props: VehicleNoticesProps) => {
  const licensingFee =
    props.type == "Vehicle"
      ? props.data.lowestLicensingFee
      : props.data.licensingFee
  return (
    <Styled.Wrapper className="notices-wrapper">
      {props.type == "VehicleChild" ? (
        <Styled.Notice className="notice">
          *אגרת הרישוי היא {formatNumber(licensingFee)}{" "}
          {`ש"ח\nהמחיר כולל מע"מ ואגרת רישוי הינו ${formatNumber(
            licensingFee + props.data.priceWithVAT,
          )} ש"ח`}
        </Styled.Notice>
      ) : (
        <Styled.Notice className="notice">
          *בתוספת אגרת רישוי בסך {formatNumber(licensingFee)} {'ש"ח כולל מע"מ'}
        </Styled.Notice>
      )}

      {(props.type == "HomePageVehicle" || props.type == "Vehicle") && (
        <VehicleLevels data={props.data} />
      )}
    </Styled.Wrapper>
  )
}

export default memo(VehicleNotices)
