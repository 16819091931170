import styled from "@emotion/styled"
import * as api from "api"
import VehicleLevelsDialog from "components/dialogs/VehicleLevelsDialog"
import { memo, useState } from "react"
import { pollutionColors, safetyColors } from "theme/colors"

const Styled = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    min-height: 1em;
  `,
  Block: styled.button`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    background: none;
    border: none;
    outline: none;
  `,
  Label: styled.span`
    font-size: 10px;
    letter-spacing: 0.4px;
    font-weight: 600;
    ${(p) => p.theme.breakpoints.up("md")} {
      letter-spacing: 0.64px;
      font-size: 16px;
    }
  `,
  Number: styled.span<{ backgroundColor: string; color: string }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    font-size: 8px;
    border-radius: 2px;
    background-color: ${(p) => p.backgroundColor};
    color: ${(p) => p.color};
    margin-inline-start: 2px;

    ${(p) => p.theme.breakpoints.up("md")} {
      margin-inline-start: 7px;
      font-size: 13px;
      width: 21px;
      height: 21px;
    }
  `,
}

type VehicleLevelsProps = {
  data: api.HomePageVehicle | api.Vehicle
}

const VehicleLevels = ({
  data: { polutionLevel, safetyLevel, safetyImage, polutionImage },
}: VehicleLevelsProps) => {
  const [imageModel, setImageModel] = useState<api.ImageModel>(null)
  const show =
    polutionLevel !== null &&
    polutionLevel >= 0 &&
    safetyLevel !== null &&
    safetyLevel >= 0 &&
    safetyImage?.imageUrl &&
    polutionImage?.imageUrl

  return show ? (
    <Styled.Wrapper>
      <Styled.Block onClick={() => setImageModel(polutionImage)}>
        <Styled.Label>דרגת זיהום</Styled.Label>
        <Styled.Number
          backgroundColor={pollutionColors[polutionLevel - 1]}
          color={polutionLevel <= 4 || polutionLevel >= 13 ? "white" : "black"}
        >
          {polutionLevel}
        </Styled.Number>
      </Styled.Block>
      <Styled.Block onClick={() => setImageModel(safetyImage)}>
        <Styled.Label>רמת האבזור הבטיחותי</Styled.Label>
        <Styled.Number
          backgroundColor={safetyColors[safetyLevel]}
          color="white"
        >
          {safetyLevel}
        </Styled.Number>
      </Styled.Block>

      <VehicleLevelsDialog
        image={imageModel}
        onClose={() => setImageModel(null)}
      />
    </Styled.Wrapper>
  ) : (
    <Styled.Wrapper />
  )
}

export default memo(VehicleLevels)
