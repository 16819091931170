import styled from '@emotion/styled'
import { SvgIconProps } from '@mui/material'
import Shared from "theme/shared"

const StyledPath1 = styled.path`
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
  fill-rule: evenodd;
`
const StyledPath2 = styled.path`
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
`
const EngineMomentIcon = (props: SvgIconProps) => (
  <Shared.OptionsSVGIcon {...props} viewBox="0 0 67 50.909">
    <g transform="translate(-26.829 -251.222)">
      <StyledPath1 d="M75.331 256.251l-2.976 5.157 2.06 1.19 2.976-5.157z" />
      <StyledPath1 d="M33.049 267.59l-1.19 2.06 5.153 2.976 1.19-2.06z" />
      <StyledPath1 d="M60.291 251.347a33.332 33.332 0 0 0-28.87 50 1.191 1.191 0 0 0 1.623.438q2.583-1.484 5.157-2.972l-1.19-2.065-3.939 2.274a30.837 30.837 0 1 1 54.437 0q-1.973-1.134-3.939-2.269l-1.2 2.06q2.578 1.493 5.162 2.972a1.189 1.189 0 0 0 1.626-.436 33.341 33.341 0 0 0-28.874-50z" />
      <StyledPath1 d="M45.248 256.251l-2.06 1.191 2.976 5.157 2.06-1.19z" />
      <StyledPath1 d="M59.1 252.543v5.952h2.382v-5.952z" />
      <StyledPath1 d="M87.533 267.59l-5.157 2.976 1.19 2.06 5.157-2.976z" />
      <StyledPath1 d="M28.147 283.5v2.379H34.1V283.5z" />
      <StyledPath1 d="M86.482 283.5v2.379h5.956V283.5z" />
      <StyledPath1 d="M70.052 266.492a1.19 1.19 0 0 0-.879.451l-10.389 13.23a1.191 1.191 0 0 0 1.853 1.5l.021-.027 5.242-6.686-3.162 7.878a1.19 1.19 0 1 0 2.209.888l6.268-15.607a1.191 1.191 0 0 0-1.163-1.632z" />
      <StyledPath2 d="M62.674 280.557a4.767 4.767 0 1 0 1.744 6.512 4.785 4.785 0 0 0-1.744-6.512zm-1.19 2.062a2.428 2.428 0 1 1-.033-.018z" />
    </g>
  </Shared.OptionsSVGIcon>
)

export default EngineMomentIcon
