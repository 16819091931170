import styled from '@emotion/styled'
import { SvgIconProps } from '@mui/material'
import Shared from "theme/shared"

const StyledPath = styled.path`
  stroke-miterlimit: 10;
  stroke-width: 1.5px;
`
const MaximumTrunkVolumeIcon = (props: SvgIconProps) => (
  <Shared.OptionsSVGIcon {...props} viewBox="0 0 75.332 50.818">
    <g>
      <StyledPath
        d="M144.994 220.413h28.662v-27.878h.008V189.3a1.156 1.156 0 0 0-1.085-1.216h-45.462a1.156 1.156 0 0 0-1.085 1.216v31.114zM128.2 190.48h43.3v27.509h-43.3z"
        transform="translate(-125.782 -187.833)"
      />
      <path
        d="M200.867 218.447l-13.591-18.98a1.508 1.508 0 0 0-1.224-.612l-13.468.122a1.36 1.36 0 0 0-1.347 1.347v17.631H127.159a1.36 1.36 0 0 0-1.347 1.347v10.941a1.39 1.39 0 0 0 1.349 1.43h7.835c0 .365-.122.733-.122 1.1a6.389 6.389 0 0 0 1.715 4.163 5.829 5.829 0 0 0 4.163 1.714 5.434 5.434 0 0 0 4.162-1.714h.123a6.383 6.383 0 0 0 1.714-4.163c0-.365-.122-.733-.122-1.1h35.991c0 .365-.122.733-.122 1.1a6.388 6.388 0 0 0 1.714 4.163 5.828 5.828 0 0 0 4.166 1.714 5.434 5.434 0 0 0 4.162-1.711 5.944 5.944 0 0 0 1.837-4.163c0-.365-.122-.733-.122-1.1h5.545a1.39 1.39 0 0 0 1.308-1.468V219.3a2.259 2.259 0 0 0-.241-.853zm-57.791 16.526a3.134 3.134 0 0 1-2.326.98 3.362 3.362 0 0 1-3.18-3.18 3.349 3.349 0 0 1 .98-2.326 3.524 3.524 0 0 1 2.2-.857 3.6 3.6 0 0 1 2.326.857 3.866 3.866 0 0 1 .857 2.326 3.518 3.518 0 0 1-.857 2.2zm28.161-6.126H145.28c-.123-.123-.245-.123-.245-.245a5.911 5.911 0 0 0-4.285-1.714 5.833 5.833 0 0 0-4.163 1.714c0 .123-.123.123-.245.245h-7.836v-8.076h42.731zm2.694-27.178l11.509-.122 11.754 16.408h-23.263zm16.777 33.307a3.135 3.135 0 0 1-2.326.98 3.2 3.2 0 0 1-2.2-5.509 3.524 3.524 0 0 1 2.2-.857 3.6 3.6 0 0 1 2.326.857 3.869 3.869 0 0 1 .86 2.329 3.518 3.518 0 0 1-.86 2.2zm7.71-6.124h-5.51c-.122-.123-.245-.123-.367-.245a5.437 5.437 0 0 0-4.163-1.714v-.005a5.833 5.833 0 0 0-4.163 1.714c0 .123-.123.123-.245.245h-10.04v-8.076h24.488z"
        transform="translate(-125.782 -187.833)"
      />
    </g>
  </Shared.OptionsSVGIcon>
)

export default MaximumTrunkVolumeIcon
