import styled from '@emotion/styled'
import { SvgIconProps } from '@mui/material'
import Shared from "theme/shared"

const StyledPath1 = styled.path`
  stroke-miterlimit: 10;
  stroke-width: 0.9px;
  fill-rule: evenodd;
`
const StyledPath2 = styled.path`
  stroke-miterlimit: 10;
  stroke-width: 0.9px;
`
const DriveSystemIcon = (props: SvgIconProps) => (
  <Shared.OptionsSVGIcon {...props} viewBox="0 0 51.365 50.436">
    <g transform="translate(-43.521 -255.466)">
      <StyledPath1 d="M83.958 279.761H65.044v1.839h18.914z" />
      <StyledPath1 d="M90.628 279.223a1.528 1.528 0 0 0-.878-1.369l-1.2-.578a2.708 2.708 0 0 0-1.176-.268H84.91a1.885 1.885 0 0 0-1.91 1.835v3.671a1.886 1.886 0 0 0 1.906 1.835h2.465a2.723 2.723 0 0 0 1.176-.269l1.2-.577a1.528 1.528 0 0 0 .878-1.37zm-1.906.187v2.537l-1.027.494a.735.735 0 0 1-.321.072H84.91v-3.669h2.465a.735.735 0 0 1 .321.071z" />
      <StyledPath2 d="M94.436 299.028a2.821 2.821 0 0 0-2.859-2.752h-9.528a2.823 2.823 0 0 0-2.859 2.752v3.672a2.823 2.823 0 0 0 2.859 2.753h9.528a2.822 2.822 0 0 0 2.859-2.753zm-1.906 0v3.672a.912.912 0 0 1-.908.918h-9.573a.917.917 0 0 1-.953-.875v-3.714a.911.911 0 0 1 .907-.917.351.351 0 0 1 .046 0h9.528a.917.917 0 0 1 .959.869.385.385 0 0 1 0 .048z" />
      <StyledPath2 d="M94.436 258.668a2.821 2.821 0 0 0-2.859-2.752h-9.528a2.822 2.822 0 0 0-2.859 2.752v3.67a2.823 2.823 0 0 0 2.859 2.753h9.528a2.821 2.821 0 0 0 2.859-2.753zm-1.906 0v3.67a.912.912 0 0 1-.908.918h-9.573a.916.916 0 0 1-.953-.873v-3.715a.912.912 0 0 1 .904-.918h9.574a.917.917 0 0 1 .959.869.4.4 0 0 1 0 .049z" />
      <StyledPath1 d="M87.769 265.091h-1.906v12.835h1.906z" />
      <StyledPath1 d="M65.887 278.843a2.82 2.82 0 0 0-2.859-2.753h-4.474l-2.374-1.522a1.956 1.956 0 0 0-1.057-.308h-8.289a2.822 2.822 0 0 0-2.859 2.752v7.34a2.821 2.821 0 0 0 2.859 2.753h8.289a1.966 1.966 0 0 0 1.057-.308l2.379-1.527h4.474a2.821 2.821 0 0 0 2.859-2.752zm-1.906 0v3.67a.92.92 0 0 1-.924.917.14.14 0 0 1-.029 0h-4.474a1.95 1.95 0 0 0-1.057.309l-2.378 1.527H46.83a.924.924 0 0 1-.953-.891v-7.367a.921.921 0 0 1 .925-.917h8.317l2.378 1.527a1.949 1.949 0 0 0 1.057.308h4.474a.923.923 0 0 1 .953.891v.027z" />
      <StyledPath2 d="M59.217 299.028a2.821 2.821 0 0 0-2.859-2.752H46.83a2.822 2.822 0 0 0-2.859 2.752v3.672a2.823 2.823 0 0 0 2.859 2.753h9.528a2.822 2.822 0 0 0 2.859-2.753zm-1.906 0v3.672a.912.912 0 0 1-.908.918H46.83a.917.917 0 0 1-.953-.875v-3.714a.912.912 0 0 1 .909-.917.321.321 0 0 1 .044 0h9.528a.914.914 0 0 1 .953.873.323.323 0 0 1 0 .043z" />
      <StyledPath2 d="M59.217 258.668a2.821 2.821 0 0 0-2.859-2.752H46.83a2.822 2.822 0 0 0-2.859 2.752v3.67a2.823 2.823 0 0 0 2.859 2.753h9.528a2.822 2.822 0 0 0 2.859-2.753zm-1.906 0v3.67a.912.912 0 0 1-.906.918H46.83a.917.917 0 0 1-.953-.875v-3.713a.913.913 0 0 1 .909-.918h9.572a.916.916 0 0 1 .953.873.338.338 0 0 1 0 .045z" />
      <StyledPath1 d="M52.547 265.091h-1.906v10.083h1.906z" />
      <StyledPath1 d="M52.547 286.184h-1.906v10.092h1.906z" />
      <StyledPath1 d="M87.769 283.432h-1.906v12.844h1.906z" />
    </g>
  </Shared.OptionsSVGIcon>
)

export default DriveSystemIcon
