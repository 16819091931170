import * as api from "api"
import DraggingSideDoorsIcon from "common/icons/VehicleOption/DraggingSideDoorsIcon"
import DriveSystemIcon from "common/icons/VehicleOption/DriveSystemIcon"
import EngineMomentIcon from "common/icons/VehicleOption/EngineMomentIcon"
import EnginePowerIcon from "common/icons/VehicleOption/EnginePowerIcon"
import EngineVolumeIcon from "common/icons/VehicleOption/EngineVolumeIcon"
import FloorLiningIcon from "common/icons/VehicleOption/FloorLiningIcon"
import GearIcon from "common/icons/VehicleOption/GearIcon"
import MaximumLoadingIcon from "common/icons/VehicleOption/MaximumLoadingIcon"
import MaximumTowingIcon from "common/icons/VehicleOption/MaximumTowingIcon"
import MaximumTrunkVolumeIcon from "common/icons/VehicleOption/MaximumTrunkVolumeIcon"
import RearDoorsOpenAngleIcon from "common/icons/VehicleOption/RearDoorsOpenAngleIcon"
import { memo } from "react"

type VehicleOptionTypesMap = {
  [type in api.VehicleOptionTypes]: JSX.Element
}

export const vehicleOptionTypesMap: VehicleOptionTypesMap = {
  Gear: <GearIcon />,
  MaximumLoading: <MaximumLoadingIcon />,
  MaximumTowing: <MaximumTowingIcon />,
  EngineVolume: <EngineVolumeIcon />,
  EnginePower: <EnginePowerIcon />,
  EngineMoment: <EngineMomentIcon />,
  RearDoorsOpenAngle: <RearDoorsOpenAngleIcon />,
  MaximumTrunkVolume: <MaximumTrunkVolumeIcon />,
  FloorLiningType: <FloorLiningIcon />,
  DraggingSideDoorsType: <DraggingSideDoorsIcon />,
  DriveSystemType: <DriveSystemIcon />,
}

type VehicleOptionIconProps = { type: api.VehicleOptionTypes }

const VehicleOptionIcon = ({ type }: VehicleOptionIconProps) => {
  return vehicleOptionTypesMap[type]
}

export default memo(VehicleOptionIcon)
