import styled from '@emotion/styled'
import { SvgIconProps } from '@mui/material'
import Shared from "theme/shared"

const StyledPath = styled.path`
  fill-rule: evenodd;
`

const EngineVolumeIcon = (props: SvgIconProps) => (
  <Shared.OptionsSVGIcon {...props} viewBox="0 0 76.259 50.063">
    <g transform="translate(-1)">
      <StyledPath
        d="M3.572 290.915v10.27h7.7v-10.27H8.707v7.7H6.139v-7.7z"
        transform="translate(28.946 -284.491)"
      />
      <StyledPath
        d="M.265 292.1v25.68h2.567V292.1z"
        transform="translate(.736 -274.133)"
      />
      <StyledPath
        d="M.331 293.3v2.568h7.48V293.3z"
        transform="translate(.669 -263.767)"
      />
      <StyledPath
        d="M21.636 291.311a2.666 2.666 0 0 0-2.267 1.249l-3.2 5.17H3.7a2.626 2.626 0 0 0-2.642 2.57v23.1a2.626 2.626 0 0 0 2.642 2.572h11.368a4.048 4.048 0 0 1 2.479.847l3.713 2.884a6.722 6.722 0 0 0 4.126 1.4h25.905a2.614 2.614 0 0 0 2.644-2.578v-6.409h4.627l3.568 4.623a1.335 1.335 0 0 0 1.058.511h3.966a3.93 3.93 0 0 0 3.966-3.85v-17.968a3.93 3.93 0 0 0-3.966-3.851h-3.965a1.336 1.336 0 0 0-1.058.516l-3.568 4.619h-4.628V300.3a2.62 2.62 0 0 0-2.659-2.568h-7.168l-3.2-5.17a2.659 2.659 0 0 0-2.267-1.249h-3.213v2.567h3.217l3.584 5.8a1.33 1.33 0 0 0 1.131.622h7.932V308a1.3 1.3 0 0 0 1.322 1.284h6.61a1.336 1.336 0 0 0 1.059-.512l3.568-4.623h3.3a1.284 1.284 0 0 1 1.322 1.284V323.4a1.284 1.284 0 0 1-1.322 1.284h-3.3l-3.568-4.619a1.336 1.336 0 0 0-1.059-.516h-6.61a1.3 1.3 0 0 0-1.322 1.284v7.7H25.385a4.043 4.043 0 0 1-2.479-.842l-3.713-2.879a6.726 6.726 0 0 0-4.126-1.409H3.7V300.3h13.221a1.332 1.332 0 0 0 1.136-.622l3.578-5.8h3.217v-2.567z"
        transform="translate(6.138 -281.045)"
      />
      <StyledPath
        d="M2.91 290.253v7.7h20.54v-7.7H2.91zm2.567 2.568h15.4v2.567H5.478z"
        transform="translate(23.17 -290.253)"
      />
      <StyledPath
        d="M6.085 293.031v12.837h2.568v-12.837z"
        transform="translate(51.446 -266.074)"
      />
      <StyledPath
        d="M1.72 292.1v25.68h2.567V292.1z"
        transform="translate(12.252 -274.133)"
      />
      <StyledPath
        d="M6.747 293.031v12.837h2.567v-12.837z"
        transform="translate(57.674 -266.074)"
      />
      <StyledPath
        d="M5.424 292.1v5.135h2.567V292.1z"
        transform="translate(45.507 -274.133)"
      />
      <StyledPath
        d="M4.233 294.619v2.568h19.256v-2.568z"
        transform="translate(35.135 -252.252)"
      />
    </g>
  </Shared.OptionsSVGIcon>
)

export default EngineVolumeIcon
