import styled from "@emotion/styled"
import { Typography, css } from "@mui/material"
import { formatCurrency } from "common/utils/formatters"
import { memo } from "react"

export const PriceBold = css`
  .price-prefix {
    font-weight: bold;
  }
`

export const PriceDialog = css`
  .price {
    margin-top: 7px;
    font-size: 24px;
  }
  .price-comment {
    font-size: 19px;
  }
  .price-prefix {
    font-weight: bold;
  }
`

export const PriceSmall = (p) => css`
  .price-wrapper {
    margin-bottom: 10px;
  }

  .price-comment {
    display: block;
  }

  .price {
    font-size: 18px;
    ${p.theme.breakpoints.up("md")} {
      font-size: 20px;
    }
  }

  .price-prefix {
    font-size: 16px;
    ${p.theme.breakpoints.up("md")} {
      font-size: 18px;
    }
  }

  .price-comment {
    color: ${p.theme.colors.bluishGrey};
    ${p.theme.breakpoints.up("md")} {
      font-size: 14px;
    }
  }

  .price-currency {
    ${p.theme.breakpoints.up("md")} {
      font-size: 13px;
    }
  }
`

const Styled = {
  Wrapper: styled.div`
    display: block;
    white-space: nowrap;
  `,
  Currency: styled.span`
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 18px;
      display: inline-block;
      transform: scaleX(0.8);
    }
  `,
  Value: styled(Typography)`
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    margin-inline-end: 5px;
    color: ${(p) => p.theme.colors.marine};
    .price-prefix {
      font-weight: normal;
      padding-inline-end: 5px;
    }

    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 20px;
    }
  `,
  Comment: styled(Typography)`
    display: inline-block;
    font-size: 12px;
    color: ${(p) => p.theme.colors.marine};
    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 16px;
    }
  `,
}

const pricePrefix = "החל מ- "
type VehiclePriceProps = { price: number; vat: boolean }

const VehiclePrice = ({ price, vat }: VehiclePriceProps) => (
  <Styled.Wrapper className="price-wrapper">
    <Styled.Value className="price">
      <span className="price-prefix">{pricePrefix}</span>
      {formatCurrency(price)}
      <Styled.Currency className="price-currency">₪</Styled.Currency>
    </Styled.Value>
    <Styled.Comment className="price-comment">
      {vat ? '(כולל מע"מ)*' : '(לא כולל מע"מ)*'}
    </Styled.Comment>
  </Styled.Wrapper>
)

export default memo(VehiclePrice)
