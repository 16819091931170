import styled from "@emotion/styled"
import { Grid, Typography, css } from "@mui/material"
import * as api from "api"
import { getDirection } from "common/utils/formatters"
import VehicleOptionIcon from "components/icons/VehicleOptionIcon"
import { Fragment, memo } from "react"

export const OptionsSmall = (p) => css`
  .option {
    font-size: 8px;
    ${p.theme.breakpoints.up("md")} {
      font-size: 10px;
      height: 30px;
    }
    ${p.theme.breakpoints.down("sm")} {
      height: 24px;
    }
  }

  .grid {
    flex: 1;
    width: auto;
    ${p.theme.breakpoints.up("md")} {
      margin: 15px 0;
    }
    ${p.theme.breakpoints.down("sm")} {
      :not(:last-of-type) {
        padding-inline-end: 8px;
      }
      :not(:first-of-type) {
        padding-inline-start: 8px;
      }
    }

    ${p.theme.breakpoints.up("md")} {
      svg {
        height: 20px;
      }
    }
  }

  .option-value {
    font-size: 10px;
    ${p.theme.breakpoints.down("sm")} {
      font-weight: bold;
    }
    ${p.theme.breakpoints.up("md")} {
      font-size: 12px;
      font-weight: 500;
    }
  }
`

const Styled = {
  OuterGrid: styled(Grid)`
    gap: 5px;
    ${(p) => p.theme.breakpoints.up("md")} {
      margin-bottom: 20px;
    }
  `,
  Grid: styled(Grid)`
    text-align: center;
    position: relative;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${(p) => p.theme.breakpoints.down("sm")} {
      max-width: 140px;
      flex: 1;
    }

    svg {
      width: auto;
      height: 24px;
    }

    ${(p) => p.theme.breakpoints.up("md")} {
      width: 190px;
      margin: 20px 0;
      svg {
        height: 30px;
      }
    }
  `,
  Option: styled(Typography)`
    display: inline-flex;
    align-items: center;
    line-height: 1.2;
    font-size: 10px;
    overflow: hidden;

    color: ${(p) => p.theme.colors.marine};
    ${(p) => p.theme.breakpoints.down("sm")} {
      height: 35px;
      padding: 0 5px;
    }

    ${(p) => p.theme.breakpoints.up("md")} {
      height: 28px;
      margin-top: 2px;
      font-size: 14px;
    }
  `,
  OptionValue: styled(Typography)<{ direction: "ltr" | "rtl" }>`
    /*! @noflip */
    direction: ${(p) => (p.direction === "ltr" ? "rtl" : "ltr")};
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    margin-top: auto;
    color: ${(p) => p.theme.colors.marine};
    ${(p) => p.theme.breakpoints.up("md")} {
      font-weight: 500;
      font-size: 26px;
    }
  `,
  Divider: styled.div`
    margin: 10px 0 0px;
    align-self: center;
    height: 1px;
    width: 33%;
    background-color: ${(p) => p.theme.colors.darkIndigo};
    ${(p) => p.theme.breakpoints.up("md")} {
      margin: 15px 0 5px;
    }
  `,
}

type VehicleOptionsProps = { data: api.VehicleMainOptions[] }

const VehicleOptions = ({ data }: VehicleOptionsProps) => {
  return (
    <Styled.OuterGrid container justifyContent="center">
      {data.map((op, i) => (
        <Fragment key={i}>
          <Styled.Grid item className="grid">
            <VehicleOptionIcon type={op.optionType} />
            <Styled.Divider />
            <Styled.Option className="option">{op.optionName}</Styled.Option>
            {op.optionComponent && (
              <Styled.OptionValue
                className="option-value"
                direction={getDirection(op.optionComponent.unit)}
              >
                {op.optionComponent.value} {op.optionComponent.unit}
              </Styled.OptionValue>
            )}
          </Styled.Grid>
        </Fragment>
      ))}
    </Styled.OuterGrid>
  )
}

export default memo(VehicleOptions)
