import styled from '@emotion/styled'
import { SvgIconProps } from '@mui/material'
import Shared from "theme/shared"

const StyledPath1 = styled.path`
  fill-rule: evenodd;
  stroke-miterlimit: 10;
  stroke-width: 0.75px;
`
const StyledPath2 = styled.path`
  stroke-miterlimit: 10;
  stroke-width: 0.75px;
`

const MaximumLoadingIcon = (props: SvgIconProps) => (
  <Shared.OptionsSVGIcon {...props} viewBox="0 0 50.675 50.652">
    <g transform="translate(-56.264 -253.662)">
      <StyledPath1 d="M65.05 265.621a2.686 2.686 0 0 0-2.638 2.218L56.7 300.814a2.688 2.688 0 0 0 2.632 3.125H103.9a2.689 2.689 0 0 0 2.667-2.708 2.611 2.611 0 0 0-.036-.414l-5.709-32.978a2.687 2.687 0 0 0-2.638-2.221zm0 1.782h33.129a.889.889 0 0 1 .884.745l5.708 32.969a.87.87 0 0 1-.688 1.021.916.916 0 0 1-.189.016H59.336a.87.87 0 0 1-.874-1.037l5.7-32.963a.888.888 0 0 1 .884-.745z" />
      <StyledPath2 d="M81.614 257.606a4.9 4.9 0 1 0 4.9 4.9 4.913 4.913 0 0 0-4.9-4.9zm0 1.782a3.119 3.119 0 1 1-3.119 3.119 3.106 3.106 0 0 1 3.091-3.12h.028z" />
      <StyledPath2 d="M81.66 254.042a8.462 8.462 0 0 0-7.142 13.077l1.493-.971a6.683 6.683 0 1 1 11.165.057l1.483.984a8.462 8.462 0 0 0-7-13.149z" />
    </g>
  </Shared.OptionsSVGIcon>
)

export default MaximumLoadingIcon
