import { SvgIconProps } from '@mui/material'
import Shared from "theme/shared"

const DraggingSideDoorsIcon = (props: SvgIconProps) => (
  <Shared.OptionsSVGIcon {...props} viewBox="0 0 52.108 50.391">
    <g>
      <path
        d="M74.608 256.967a29.192 29.192 0 0 0-26.56 17.043l-4.875 10.921 12.647 22.427h39.461v-50.391zm17.85 47.607H57.445l-11.139-19.765.015-.035 4.3-9.586a26.366 26.366 0 0 1 23.987-15.371h17.85z"
        transform="translate(-43.173 -256.967)"
      />
      <path
        d="M74.607 262.9c-9.44 0-17.1 4.542-20.481 12.15l-3.718 8.242h38.859V262.9zm11.838 17.673H54.734l1.966-4.343c2.98-6.6 9.675-10.543 17.909-10.543H86.37v.074h.075z"
        transform="translate(-43.173 -256.967)"
      />
      <path
        d="M81.753 289.63a1.461 1.461 0 0 0 1.446 1.426h4.731a1.425 1.425 0 0 0 1-.425 1.386 1.386 0 0 0 .4-1 1.461 1.461 0 0 0-1.445-1.427h-4.727a1.418 1.418 0 0 0-1.405 1.426z"
        transform="translate(-43.173 -256.967)"
      />
    </g>
  </Shared.OptionsSVGIcon>
)

export default DraggingSideDoorsIcon
