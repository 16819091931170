import styled from '@emotion/styled'
import { SvgIconProps } from '@mui/material'
import Shared from "theme/shared"

const StyledPath = styled.path`
  stroke-miterlimit: 10;
  stroke-width: 0.45px;
`

const GearIcon = (props: SvgIconProps) => (
  <Shared.OptionsSVGIcon {...props} viewBox="0 0 49.967 50.734">
    <g transform="translate(-28.464 -258.034)">
      <StyledPath d="M72.068 273.382a1.116 1.116 0 0 0-1.112 1.112v7.784h-16.4v-7.784a1.112 1.112 0 0 0-2.224 0v7.784H35.939v-7.784a1.112 1.112 0 0 0-2.224 0v17.792a1.112 1.112 0 0 0 2.224 0V284.5h16.4v7.784a1.112 1.112 0 0 0 2.224 0V284.5h17.516a1.113 1.113 0 0 0 1.112-1.112v-8.9a1.132 1.132 0 0 0-1.123-1.106z" />
      <StyledPath d="M34.827 296.267a6.138 6.138 0 1 0 6.138 6.138 6.138 6.138 0 0 0-6.138-6.138zm0 10.041a3.914 3.914 0 1 1 3.914-3.914 3.914 3.914 0 0 1-3.914 3.914z" />
      <StyledPath d="M53.442 296.267a6.138 6.138 0 1 0 6.138 6.133 6.138 6.138 0 0 0-6.138-6.133zm0 10.041a3.914 3.914 0 1 1 3.912-3.916 3.919 3.919 0 0 1-3.912 3.916z" />
      <StyledPath d="M72.068 258.259a6.138 6.138 0 1 0 6.138 6.138 6.143 6.143 0 0 0-6.138-6.138zm0 10.041a3.914 3.914 0 1 1 3.914-3.914 3.92 3.92 0 0 1-3.914 3.914z" />
      <StyledPath
        d="M34.827 296.267a6.138 6.138 0 1 0 6.138 6.138 6.138 6.138 0 0 0-6.138-6.138zm0 10.041a3.914 3.914 0 1 1 3.914-3.914 3.914 3.914 0 0 1-3.914 3.914z"
        transform="translate(0 -38)"
      />
      <StyledPath
        d="M53.442 296.267a6.138 6.138 0 1 0 6.138 6.133 6.138 6.138 0 0 0-6.138-6.133zm0 10.041a3.914 3.914 0 1 1 3.912-3.916 3.919 3.919 0 0 1-3.912 3.916z"
        transform="translate(0 -38)"
      />
    </g>
  </Shared.OptionsSVGIcon>
)

export default GearIcon
