import styled from '@emotion/styled'
import { SvgIconProps } from '@mui/material'
import Shared from "theme/shared"

const StyledPath = styled.path`
  stroke-miterlimit: 10;
  stroke-width: 0.4px;
`

const FloorLiningIcon = (props: SvgIconProps) => (
  <Shared.OptionsSVGIcon {...props} viewBox="0 0 51.05 51.542">
    <g>
      <StyledPath
        d="M48.2 213.353v33.839h49.65V197.05H48.2zm48.39 17.528v15.088H49.464v-15.084zm0-16.3v15.081H49.464v-15.083zm0-16.3v15.077H49.464v-15.08z"
        transform="translate(-47.5 -196.35)"
      />
    </g>
  </Shared.OptionsSVGIcon>
)

export default FloorLiningIcon
